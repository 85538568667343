<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
              content: $t('label.return'),
              placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{  this.isEdit ? `${$t('label.edit')} ${$t('label.inspection')}: ${this.EventName}` : `${$t('label.nueva')} ${$t('label.inspection')}` }} 
        </h6>
      </CCol>
    </CRow>
    <CRow class="justify-content-center px-3">
      <CCol sm="12" lg="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.basicData')">
            <CCard class="card-border">
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal1.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal1.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal1.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal1, 1)"
                        :disabled="dataContainer?.FgCertified??false"
                      />
                      <CButton
                        v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="dataContainer?.FgCertified??false"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal2.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal2.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal2.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal2, 2)"
                        :disabled="dataContainer?.FgCertified??false"
                      />
                      <CButton
                        v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="dataContainer?.FgCertified??false"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal3.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal3.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal3.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal3, 3)"
                        :disabled="dataContainer?.FgCertified??false"
                      />
                      <CButton
                        v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="dataContainer?.FgCertified??false"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal4.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal4.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal4.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal4, 4)"
                        :disabled="dataContainer?.FgCertified??false"
                      />
                      <CButton
                        v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="dataContainer?.FgCertified??false"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" class="text-invalid-feedback text-center" 
                  v-if="!ValidateTpCargoStatus && Validate && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal"
                >
                  <label>{{ $t('label.AtLeastSealRequired') }}</label>
                </CCol>
                <CCol sm="12" lg="6">
                  <CSelect v-if="!isEdit"
                    size="sm"
                    :label="$t('label.Event')"
                    :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-9'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.Event.$model"
                    :is-valid="hasError($v.Event)"
                    :invalid-feedback="errorMessage($v.Event)"
                    :options="EventOptions"
                    @change="DateRangeChange"
                    :disabled="dataContainer?.FgCertified??false"
                  />
                  <CInput v-else
                    size="sm"
                    :label="$t('label.Event')"
                    addLabelClasses="text-right required"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.EventName.$model"
                    :is-valid="hasError($v.Event)"
                    :invalid-feedback="errorMessage($v.Event)"
                    disabled
                  />
                  <!--<CSelect
                    size="sm"
                    :disabled="EventDateList.length==0"
                    addLabelClasses="text-right EventDate"
                    :label="$t('label.eventDate')"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                    v-model="$v.EventDate.$model"
                    :is-valid="hasError($v.EventDate)"
                    :invalid-feedback="errorMessage($v.EventDate)"
                    :options="EventDateOptions"
                  />-->
                  <CTextarea
                    size="sm"
                    rows="2"
                    :label="$t('label.observation')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.Observation.$model"
                    v-uppercase
                    :is-valid="hasError($v.Observation)"
                    :invalid-feedback="errorMessage($v.Observation)"
                    :disabled="dataContainer?.FgCertified??false"
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row mb-3 px-0">
                    <label style="font-size: 12px;" class="col-form-label-sm col-sm-12 col-lg-3 required text-right mb-0">{{`${$t('label.eventDate')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-9 align-items-center mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.TransactionDate.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                        :disabled="isEdit || (dataContainer?.FgCertified??false)"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="TransactionDate"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.TransactionDate)"
                            size="sm"
                            :disabled="isEdit || (dataContainer?.FgCertified??false)"
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                  <CSelect
                    v-if="isEdit && ValidateTpCargoStatus"
                    size="sm"
                    :options="statusOptions"
                    :value.sync="Status"
                    :label="$t('label.status')"
                    :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                    addLabelClasses="required text-right"
                    :is-valid="statusSelectColor"
                    :disabled="dataContainer?.FgCertified??false"
                  />
                </CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>

        
      </CCol>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="!dataContainer?.FgCertified">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
      </CCol>
    </CRow>
    <ModalSeal
      :modal="ModalSealActive"
      :SealJson="SealJson"
      :isEdit="isEdit"
      @CloseModal="(ModalSealActive=false, SealJson={})"
      @UpdateSeal="UpdateSeal"
      @UpdateRegister="UpdateRegister"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';
import UpperCase from '@/_validations/uppercase-directive';
import SealRegisterValidations from '@/_validations/yard-management/container/sealRegisterValidations';
import ModalMixin from '@/_mixins/modal';
import ModalSeal from '../../../modal-seal';

function data() {
  return {
    isSubmit: false,
    ModalSealActive: false,
    Validate: false,
    SealJson: {},
    Seal: {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    },
    ConditionList: [],
    Event: '',
    EventName: '',
    EventList: [],
    //EventDate: '',
    //EventDateList: [],
    CargoJson: {},
    TransactionDate: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    Observation: '',
    Status: 0,
  }
}

//methods
async function getService() {
  let VisitCargoInspectionId = this.SealItem?.VisitCargoInspectionId ?? '';
    let requests =  [
      this.$http.ejecutar("GET", "SealCondition-list"),
      this.$http.ejecutar("GET",`YardEvent-by-YardCargoId?VisitCargoInspectionId=${VisitCargoInspectionId}&YardCargoId=${this.dataContainer.YardCargoId}`),
    ];

  await Promise.all(requests)
    .then((responses) => {
      this.ConditionList = responses[0].data.data ?? [];
      this.EventList = responses[1].data.data ?? [];
    })
    .catch((err) => {
      this.notifyError({text: err});
    })
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if (this.ValidateEventDischargeShipSide) {
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate) {
          throw this.$t('label.ErrorConfirmationDatePleaseCheck');
        }
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate) {
          throw this.$t('label.ErrorReceptionDatePleaseCheck');
        }
      }else{
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate ) {
          throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
        }
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate ) {
          throw this.$t('label.ErrorCurrentDatedPleaseCheck');
        }
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let CargoJson = {
      YardCargoId: this.dataContainer.YardCargoId,
      Seal1: this.Seal.Seal1.Seal,
      Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
      Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
      ObservationSeal1: this.Seal.Seal1.ObservationSeal,
      Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
        this.Seal.Seal1.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal2: this.Seal.Seal2.Seal,
      Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
      Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
      ObservationSeal2: this.Seal.Seal2.ObservationSeal,
      Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
        this.Seal.Seal2.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal3: this.Seal.Seal3.Seal,
      Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
      Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
      ObservationSeal3: this.Seal.Seal3.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
        this.Seal.Seal3.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal4: this.Seal.Seal4.Seal,
      Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
      Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
      ObservationSeal4: this.Seal.Seal4.ObservationSeal,
      Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
        this.Seal.Seal4.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      TransactionDate: this.isEdit ? this.SealItem.TransactionDate : DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
      EventId: this.Event,
      Observation: this.Observation.trim(),
    }

    if (this.isEdit) {
      CargoJson.VisitCargoInspectionId = this.SealItem?.VisitCargoInspectionId ?? '';
      CargoJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'YardCargoSealInspection-update' : 'YardCargoSealInspection-insert';

    this.$http.ejecutar(Method, Route, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.$emit("Update-list");
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function UpdateRegister(images, index) {
  this.$store.state.yardManagement.loading = true;

  switch (index) {
    case 1:
      this.CargoJson.Seal1DocumentJson = images ?? [];
      break;
    case 2:
      this.CargoJson.Seal2DocumentJson = images ?? [];
      break;
    case 3:
      this.CargoJson.Seal3DocumentJson = images ?? [];
      break;
    case 4:
      this.CargoJson.Seal4DocumentJson = images ?? [];
      break;
  }

  this.$http.put('YardCargoReception-update', this.CargoJson, { root: 'CargoJson' })
    .then(async (response) => {
      await this.$emit("Update-list");
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: err});
    })
}

function DateRangeChange(event) {
  this.Event = event.target.value;
  if (this.ValidateEventDischargeShipSide) {
    this.previousDate = DateFormater.formatDateRange(this.dataContainer.ConfirmationDate);
    this.laterDate = DateFormater.formatDateRange(this.dataContainer.ReceptionDate);
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer.ConfirmationDate));
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer.ReceptionDate));
  }else{
    this.getDateRange();
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function SelectCondition(Seal, index) {
  let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
  if (process.env.VUE_APP_SEAL_CONDITION_INTACT == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
    WithoutSeal) {
    let CurrentSeal = {
      index: index,
      CurrentSeal: Seal.CurrentSeal,
      Seal: WithoutSeal ? '' : Seal.CurrentSeal,
      NewSeal: '',
      ConditionSeal: Seal.ConditionSeal,
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: Seal.SealDocumentJson,
    }
    this.UpdateSeal(CurrentSeal);
  }
}

function ConditionWithoutSeal(ConditionSeal) {
  if (typeof ConditionSeal == 'string') {
    return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
  }else{
    return false;
  }
}

function UpdateSeal(Update) {
  switch (Update.index) {
    case 1:
      this.Seal.Seal1 = Update;
      break;
    case 2:
      this.Seal.Seal2 = Update;
      break;
    case 3:
      this.Seal.Seal3 = Update;
      break;
    case 4:
      this.Seal.Seal4 = Update;
      break;
    default:
      break;
  }
}

/*function addEventDate(event) {
  this.Event = event.target.value;
  this.EventDate = '';
  if (this.Event) {
    let EventData = this.EventList.find(item => item.EventId == this.Event);
    this.EventDateList = EventData?.DetailJson ?? [];
  }else{
    this.EventDateList = [];
  }
}*/

function getData(val) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.Seal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.NewSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.ConditionSeal = val?.SealJson[0]?.Seal1Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealAssignmentId = val?.SealJson[0]?.Seal1Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal1.ObservationSeal = val?.SealJson[0]?.Seal1Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal1.SealDocumentJson = val?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.Seal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.NewSeal =val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.ConditionSeal = val?.SealJson[0]?.Seal2Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealAssignmentId = val?.SealJson[0]?.Seal2Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal2.ObservationSeal = val?.SealJson[0]?.Seal2Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal2.SealDocumentJson = val?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.Seal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.NewSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.ConditionSeal = val?.SealJson[0]?.Seal3Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealAssignmentId = val?.SealJson[0]?.Seal3Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal3.ObservationSeal = val?.SealJson[0]?.Seal3Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal3.SealDocumentJson = val?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.Seal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.NewSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.ConditionSeal = val?.SealJson[0]?.Seal4Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealAssignmentId = val?.SealJson[0]?.Seal4Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal4.ObservationSeal = val?.SealJson[0]?.Seal4Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal4.SealDocumentJson = val?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];

  this.TransactionDate = DateFormater.formatDateTimeWithSlash(val.TransactionDate);
  this.Event = val.EventId;
  this.EventName = val['EventName'+_lang] ?? '';
  this.Observation = val.Observation ?? '';
  this.Status = val.FgActVisitCargoInspection ? 1 : 0;
  this.Validate = true;

  this.CargoJson = {
    YardCargoId: this.dataContainer.YardCargoId,
    Seal1: this.Seal.Seal1.Seal,
    Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
    Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
    ObservationSeal1: this.Seal.Seal1.ObservationSeal,
    Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
      this.Seal.Seal1.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal2: this.Seal.Seal2.Seal,
    Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
    Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
    ObservationSeal2: this.Seal.Seal2.ObservationSeal,
    Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
      this.Seal.Seal2.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal3: this.Seal.Seal3.Seal,
    Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
    Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
    ObservationSeal3: this.Seal.Seal3.ObservationSeal,
    Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
      this.Seal.Seal3.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal4: this.Seal.Seal4.Seal,
    Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
    Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
    ObservationSeal4: this.Seal.Seal4.ObservationSeal,
    Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
      this.Seal.Seal4.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    TransactionDate: this.isEdit ? this.SealItem.TransactionDate : DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
    EventId: this.Event,
    Observation: this.Observation.trim(),
  }
  this.$v.$touch();
}

function statusSelectColor() {
  return this.Status === 1;
}

function getNewData() {
  this.Seal.Seal1.CurrentSeal = this.dataContainer?.Seal1 ?? '';
  this.Seal.Seal1.Seal = this.dataContainer?.Seal1 ?? '';
  this.Seal.Seal1.ConditionSeal = this.Seal.Seal1.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = this.dataContainer?.Seal2 ?? '';
  this.Seal.Seal2.Seal = this.dataContainer?.Seal2 ?? '';
  this.Seal.Seal2.ConditionSeal = this.Seal.Seal2.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = this.dataContainer?.Seal3 ?? '';
  this.Seal.Seal3.Seal = this.dataContainer?.Seal3 ?? '';
  this.Seal.Seal3.ConditionSeal = this.Seal.Seal3.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = this.dataContainer?.Seal4 ?? '';
  this.Seal.Seal4.Seal = this.dataContainer?.Seal4 ?? '';
  this.Seal.Seal4.ConditionSeal = this.Seal.Seal4.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealDocumentJson = this.dataContainer?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];
  this.$v.$reset();
}

function getDateRange() {
  this.previousDate = DateFormater.formatDateRange(this.dataContainer.ReceptionDate);
  this.laterDate = new Date();
  this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer.ReceptionDate));
  this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
}

function Reset() {
  this.Seal = {
    Seal1: {
      index: 1,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal2: {
      index: 2,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal3: {
      index: 3,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal4: {
      index: 4,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
  };
  this.ConditionList = [];
  this.Event = '';
  this.EventName = '';
  this.EventList = [];
  //this.EventDate = '';
  //this.EventDateList = [];
  this.TransactionDate = '';
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.Observation = '';
  this.Status = 0;
  this.Validate = false;
  this.$v.$reset();
  this.$emit("Close");
}

//computed
function ConditionOptions() {
  if(this.ConditionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.condition'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.condition'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.ConditionList.map(function(e){
      chart.push({
        value: e.SealConditionId,
        label: e[`SealConditionName${_lang}`],
      })
    })
    return chart;
  }
}

function EventOptions() {
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventList.map(function(e){
      chart.push({
        value: e.EventId,
        label: `${e[`EventName${_lang}`]}`,
      })
    })
    return chart;
  }
}

/*function EventDateOptions() {
  if(this.EventDateList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.EventDateList.map(e => {
      chart.push({
        value: e.VisitCargoInspectionId,
        label: DateFormater.formatDateTimeWithSlash(e.TransactionDate),
      })
    })
    return chart;
  }
}*/

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
}

function ValidateEventDischargeShipSide() {
  return process.env.VUE_APP_EVENT_DISCHARGE_SHIP_SIDE == this.Event.toUpperCase();
}

function VerifySeal() {
  if (this.ValidateTpCargoStatus) {
    return {
      ConditionSeal1: false,
      ConditionSeal2: false,
      ConditionSeal3: false,
      ConditionSeal4: false,
    }
  } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
      return {
        ConditionSeal1: true,
        ConditionSeal2: true,
        ConditionSeal3: true,
        ConditionSeal4: true,
      }
  } else {
    return {
      ConditionSeal1: this.Seal.Seal1.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? false : true,
      ConditionSeal2: this.Seal.Seal2.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? false : true,
      ConditionSeal3: this.Seal.Seal3.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? false : true,
      ConditionSeal4: this.Seal.Seal4.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? false : true,
    }
  }
}

export default {
  name: 'seal-register',
  props: { Active: Boolean, SealItem: Object, isEdit: Boolean },
  components: {
    ModalSeal,
  },
  data,
  mixins: [ModalMixin],
  directives: UpperCase,
  validations(){
    return SealRegisterValidations(this.VerifySeal, this.ValidPreviousDate, this.ValidLaterDate);
  },
  methods: {
    getService,
    Submit,
    UpdateRegister,
    DateRangeChange,
    validateDateRange,
    SelectCondition,
    ConditionWithoutSeal,
    UpdateSeal,
    //addEventDate,
    getData,
    getNewData,
    statusSelectColor,
    getDateRange,
    Reset,
  },
  computed: {
    ConditionOptions,
    EventOptions,
    //EventDateOptions,
    ValidateTpCargoStatus,
    ValidateEventDischargeShipSide,
    VerifySeal,
    ...mapState({
        dropItemInspection: state => state.yardManagement.dropItemInspection,
        dataContainer: state => state.yardManagement.dataContainer,
    })
  },
  watch: {
    Active: async function (NewVal) {
      if (NewVal) {
        this.$store.state.yardManagement.loading = true;
        await this.getDateRange();
        await this.getService();
        if (this.isEdit) {
          await this.getData(this.SealItem);
        }else {
          await this.getNewData();
        }
        this.$store.state.yardManagement.loading = false;
      }
    },
    TransactionDate: function(val){
      try{
        if(val){
          if (this.ValidateEventDischargeShipSide) {
            if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate) {
              throw this.$t('label.ErrorConfirmationDatePleaseCheck');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
              throw this.$t('label.ErrorReceptionDatePleaseCheck');
            }
          }else{
            if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate) {
              throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
              throw this.$t('label.ErrorCurrentDatedPleaseCheck');
            }
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    dropItemInspection: function (NewVal, OldVal) {
      if(OldVal == 1){
        this.Reset();
      }
    },
  },
};
</script>